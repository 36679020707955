
import { Component, Prop, Vue } from 'vue-property-decorator'
import ServiceBlock from '@/components/page/service/ServiceBlock.vue'
import FormParserCatalogItem from '@/components/general/FormParserCatalogItem.vue'
import AppPhoneField from '@/components/general/AppPhoneField.vue'

import dayjs from 'dayjs'

import { PageModule } from '@/store/page'
import { UserModule } from '@/store/user'
import { ServiceModule } from '@/store/service'

// eslint-disable-next-line no-unused-vars
import { EventLayout } from '@/model/index'

// eslint-disable-next-line no-unused-vars
import { ComputedServiceForm, IDatetime, IFormElement, IImageFormObject } from '@/model/page/service'

interface IFormDatetimes {
  times: Array<IDatetime>
  time: number
  daytime: number
}

interface IFormDatetimesMonth {
  el: any
  offset: number
  width: number
}

type TFormDatetimes = Record<string, Array<IFormDatetimes> | undefined>

@Component({
  components: {
    ServiceBlock,
    FormParserCatalogItem,
    AppPhoneField
  }
})
export default class FormParser extends Vue {
  @Prop({ required: true, type: Array }) form!: Array<IFormElement>
  @Prop({ type: Function, required: true }) callbackForm!: (form: ComputedServiceForm) => void
  @Prop({ type: Boolean }) disabled!: boolean
  @Prop({ type: Boolean }) disableButton!: boolean
  @Prop({ type: Boolean, default: false }) showPrice!: boolean
  @Prop({ type: Boolean, default: true }) canSelectPastDate!: boolean
  @Prop({ type: Boolean }) loadingButton!: boolean

  imageModal = {
    view: false,
    src: ''
  }

  catalogModal = {
    view: false,
    element: {},
    item: {},
    index: ''
  }

  dayjs = dayjs
  mountedForm = false
  isLoading = false

  swiperOption = {
    slidesPerView: 'auto',
    spaceBetween: 10,
    mousewheel: true
  }

  swiperDateOption = {
    freeMode: true,
    freeModeMomentum: false,
    slidesPerView: 'auto',
    spaceBetween: 10
  }

  menu = false
  valid = true
  datetimes: TFormDatetimes = {}
  months: Array<IFormDatetimesMonth> = []

  get isDark() { return PageModule.isDark }

  get filteredForm() {
    const res = this.form
      .map(element => {
        if (element.type === 'select' && !element.value) {
          element.value = element.values[0].value
        } else if (['text', 'string'].includes(element.type)) {
          element.value = element.value || ''
        } else if (element.type === 'switch' && element.value === '') {
          element.value = 0
        }

        return element
      })
      .filter((element: IFormElement) => {
        return this.showElement(element.showIfElementId, element.showIfElementValue, element)
      })
    return res.filter(item => this.showElelmntIfParentVisible(item, res))
  }

  get finalCost() {
    if (!this.showPrice) {
      return ''
    }

    const cost = this.filteredForm.reduce((sum: number, element: IFormElement) => {
      let price = parseFloat(element.companyPricePrice || '0')

      switch (element.type) {
        case 'catalog': {
          price = (element.values as IFormElement[]).reduce((sum: number, item: IFormElement) => {
            const cost = parseFloat(item.companyPricePrice || '0')
            const amount = item.value as number || 0
            return sum + cost * amount
          }, 0)
          break
        }
        case 'switch':
        case 'quantity': {
          price = element.value ? price * (element.value as number || 0) : 0
          break
        }
        case 'checkbox': {
          price = (element.values as IFormElement[]).reduce((sum: number, item: IFormElement) => {
            const cost = parseFloat(item.companyPricePrice || '0')
            const isChecked = (element.value + '' || '').includes(item.value + '' || '')
            return sum + cost * Number(isChecked)
          }, 0)
          break
        }
        case 'panel':
        case 'select':
        case 'radio': {
          if (element.value) {
            const item = (element.values as IFormElement[]).find(({ value }) => parseFloat(value + '') === parseFloat(element.value + ''))

            price = parseFloat(item?.companyPricePrice || '0')
          } else {
            price = 0
          }
          break
        }
        default: {
          price = 0
        }
      }

      return sum + price
    }, 0)

    return cost ? `${cost} ₽` : ''
  }

  get isActiveCheckbox() {
    return (element: IFormElement, item: any) => {
      let value: string | string[] = element.value as string

      if (value == null) { return false }

      value = value.split(', ')

      return item.selected || value.includes(item.title)
    }
  }

  openModalImage(image: string) {
    this.imageModal.view = true
    this.imageModal.src = image
  }

  getPrice({ companyPricePrice: price, companyPriceCurrencySign: sign }: IFormElement) {
    return price ? `${price} ${sign}` : ''
  }

  getSelectValue(element: any) {
    return element.values.find((elementVariant: IFormElement) => elementVariant.title === element.value)?.value || element.values[0].value
  }

  formatSelectorText(element: IFormElement) {
    if (element.companyPricePrice) {
      return `${element.title} ${element.companyPricePrice ? '- ' + element.companyPricePrice + element.companyPriceCurrencySign : ''}`
    }

    return element.title
  }

  changeSelection(element: IFormElement, event: any) {
    element.value = event
  }

  updateCheckbox(element: IFormElement, child: IFormElement, value: boolean) {
    let array = (element.value as string || '').split(', ').filter(Boolean)

    if (value) {
      array.push(child.value as string)
    } else {
      array = array.filter((value: string) => parseInt(value) !== child.value)
    }

    const finalValue = array.join(', ')

    element.value = finalValue
  }

  formatDate(date: string | number) {
    if (typeof date === 'string') {
      return date.split('-').reverse().join('.')
    } else {
      return dayjs(date * 1000).format('DD.MM.YYYY')
    }
  }

  getElement(id: number | string) {
    return this.form.find((element: any) => element.id === id)
  }

  showElement(id: number | string, value: number | string | boolean, formElement?: IFormElement) {
    const element = this.getElement(id || -1)
    const booleanElementsValue = ['switch']
    let valueSynonym!: string | number | boolean

    if (element?.type === 'panel' || element?.type === 'radio') {
      // @ts-ignore
      valueSynonym = element.values.find((val) => val?.value === value)?.name
    } else if (element?.type === 'switch') {
      if (typeof element?.value === 'boolean') {
        valueSynonym = Number(element?.value)
      } else {
        valueSynonym = element?.value === 'Да' ? 1 : 0
      }
    }

    if (!element || !id) { return true }
    let isShow = String(element.value) === String(value) || String(element.value) === valueSynonym || (booleanElementsValue.includes(element.type) && (valueSynonym === value))

    if (element.type === 'checkbox') {
      const valueInput = element.value || (element.values as IFormElement[]).filter(({ selected }) => selected).map(({ value }) => value).join(' ,')

      isShow = (valueInput as string).includes(String(value))
    }

    if (isShow === false && formElement && ['radio', 'checkbox', 'switch'].includes(formElement.type)) {
      formElement.value = '' // Удалить значения, если сктыры элементы
    }

    return isShow
  }

  showElelmntIfParentVisible(element: IFormElement, res: any[]) : boolean {
    if (element.showIfElementId === undefined) return true
    const parentElement = res.find(item => item.id === element.showIfElementId)
    if (element.showIfElementId === 0) return true
    return res.includes(parentElement)
  }

  getValue({ id }: IFormElement) {
    const element = this.getElement(id) as IFormElement
    return element.values.length ? element.values : element.value
  }

  isRequired(element: IFormElement) {
    return element.required && this.showElement(element.showIfElementId, element.showIfElementValue)
  }

  required(element: IFormElement) {
    return (value: string) => {
      if (this.isRequired(element)) {
        return !!value || 'Обязательное поле'
      }
      return true
    }
  }

  getImages(id: number) {
    return [{ isLabel: true }, ...(this.getElement(id)?.values || [])]
  }

  deleteImage(id: number, index: number) {
    const element = this.getElement(id)

    if (element) {
      element.values.splice(index - 1, 1)
    }
  }

  timeToUnix(time: string) {
    if (time) {
      const [hours, minutes] = time.split(':').map(parseFloat)

      return (hours * 60 * 60) + minutes * 60
    }
    return 0
  }

  handleDateChange(element: IFormElement, $event: string) {
    this.$set(element, 'date', $event)
  }

  handleTimeChange(element: IFormElement, $event: string) {
    this.$set(element, 'time', $event)
  }

  async setDatetime(element: IFormElement, datetime: IDatetime) {
    const { id } = this.$route.params

    const items = await ServiceModule.updateTimestamp({ id, date: datetime.timestamp })
    this.$set(element, 'timestamp', datetime.timestamp)
    this.$set(element, 'value', null)
    if (!items) {
      this.$set(element, 'times', [])
      return
    }
    this.$set(element, 'times', items.map(item => {
      const time = new Date(item.timestamp * 1000)
      const hours = ('00' + time.getHours()).slice(-2)
      const minutes = ('00' + time.getMinutes()).slice(-2)

      return {
        title: `${hours}:${minutes}`,
        disabled: !item.isAvailable,
        timestamp: item.timestamp
      }
    }))
  }

  setDatetimeValue(element: IFormElement, timestamp: number) {
    this.$set(element, 'value', timestamp)
  }

  getDatetimes(id: number) {
    const element = this.getElement(id)
    const dayOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
    const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
    let finalArray: Array<IDatetime> = []

    if (element?.availableDates) {
      finalArray = element.availableDates
        .map((item, idx) => {
          const date = new Date(item.timestamp * 1000)
          const day = dayOfWeek[date.getDay()]
          const dayNumeric = date.getDate()
          const month = dayNumeric === 1 || idx === 0
            ? `${months[date.getMonth()]} ${date.getFullYear()}`
            : null
          return {
            day,
            dayNumeric,
            timestamp: item.timestamp,
            disabled: !item.isAvailable,
            month
          }
        })
    }

    return finalArray
  }

  async updateImage(event: EventLayout<HTMLInputElement>, id: number) {
    const element = this.getElement(id)

    if (!element) return

    const length = element.values.length
    const files = await Promise.all<IImageFormObject>(
      Array.from(event.target.files as ArrayLike<File>)
        .map((file: File, index): Promise<IImageFormObject> => {
          return new Promise((resolve) => {
            const { type } = file
            const reader = new FileReader()

            const extentions = ['image/jpeg', 'image/png']
            if (!extentions.includes(type)) {
              return PageModule.SEND_NOTIFICATION({ type: 'error', message: this.$t('error.wrongImageType') as string })
            }

            reader.onload = (e) => {
              UserModule.uploadFile((event.target.files as FileList)[index])
                .then((response) => {
                  (element.values[length + index] as IImageFormObject).loading = false;
                  (element.values[length + index] as IImageFormObject).value = response.items[0].name || ''
                })
                .catch((error) => {
                  element.values.splice(length + index, 1)
                  this.$handleApiError(error)
                  if (error?.message === 'ignoreError') {
                    PageModule.SEND_NOTIFICATION({ type: 'error', message: 'Произошла ошибка при загрузке изображения' })
                  }
                })
              return resolve({
                src: reader.result as string || '',
                loading: true,
                value: ''
              })
            }

            reader.readAsDataURL(file)
          })
        })
    )

    const input = this.$refs[id] as any

    if (input) {
      input[0].value = []
    }

    element.values = element?.values?.concat(files)
  }

  async handleForm(customCallback?: (formData: ComputedServiceForm) => any, withValidation = true) {
    const validation = this.filteredForm.filter((element) => {
      if (!element.required) return false

      if (element.type === 'catalog') {
        if (!element?.values?.some(item => item.value)) {
          this.$set(this.getElement(element.id) as IFormElement, 'error', `Поле "${element.title}" обязательно для заполнения`)
          return true
        } else {
          this.$set(this.getElement(element.id) as IFormElement, 'error', '')
        }
      }

      if (element.type === 'quantity' && !element.value) {
        return true
      }

      if (element.type === 'image' && !element?.values?.length) {
        this.$set(this.getElement(element.id) as IFormElement, 'error', 'Необходимо добавить изображение')
        return true
      }

      return false
    })

    if (withValidation) {
      if (!(this.$refs.form as any).validate() || validation.length) {
        return
      }
    }

    const handleArr = (array: any) => array.map(({ id, type, value, values, time, times, date, text, items }: any) => {
      const obj: { id: number, value: string | number | any[], text?: string } = { id, value }

      if (type === 'autocomplete') {
        obj.text = items[0]?.text
      }

      if (values?.length && ['image', 'catalog'].includes(type)) {
        obj.value = handleArr(values)
      }

      if (type === 'image') {
        obj.value = values.map((innerValue: any) => {
          if (typeof innerValue === 'string') {
            return innerValue?.replace('https://uploads.domyland.ru/', '')
          } else {
            return innerValue.value
          }
        })
      }

      if (type === 'date') {
        let date = null

        if (typeof obj.value === 'string' && obj.value) {
          date = new Date(obj.value).getTime() / 1000
          date = '' + obj.value
          obj.value = date
        }
      }

      if (type === 'catalog') {
        obj.value = values.filter(({ value }: any) => value)
      }

      if (type === 'switch') {
        obj.value = value === 'Да' ? 1 : 0
      }

      if (type === 'panel' || type === 'radio') {
        // @ts-ignore
        obj.value = values.find((val: IFormElement) => value === val?.name || value === val?.title)?.value || value
      }

      if (type === 'select') {
        obj.value = values.find((val: IFormElement) => value === val.title)?.value || value
      }

      if (type === 'checkbox') {
        const splitValue: string[] = value.split(', ')
        const finalValues: string[] = []
        value = ''

        splitValue.forEach((val: string) => {
          finalValues.push(values.find((innerValue: IFormElement) => val === innerValue.title)?.value || val)
        })

        obj.value = finalValues.join(', ')
      }

      const hasDatetimeValue = (time && date) || typeof date === 'object'
      const hasTimeSlider = times && times.length

      if (type === 'datetime' && !hasTimeSlider && hasDatetimeValue) {
        if (typeof date !== 'object') {
          const paternDate = `${date}-${time.replace(':', '-')}` // 2020-07-09-01-02
            .split('-')
            .map(t => parseInt(t)) // [2020, 07, 09, 01, 02]

          paternDate[1]-- // js Date minus month

          const finalDate = new Date(...paternDate as [number, number, number, number, number])

          obj.value = Math.ceil(finalDate.getTime() / 1000)
        } else {
          obj.value = Math.ceil(date.getTime() / 1000)
        }
      }

      return obj
    }) as ComputedServiceForm

    const formData = handleArr(this.form)

    this.isLoading = true

    try {
      if (customCallback) {
        await customCallback(formData)
      } else {
        this.callbackForm(formData)
      }
    } catch (error) {
      this.$handleApiError(error, this)
    } finally {
      this.isLoading = false
    }
  }

  mounted() {
    this.form.forEach(element => {
      if (element.type === 'catalog') {
        element.values.forEach((item, index) => {
          // @ts-ignore
          if (element.value[index]?.quantity) {
            // @ts-ignore
            item.value = element.value[index].quantity
          }
        })
      }

      if (element.type === 'image') {
        // @ts-ignore
        element.values.push(...element.value)
      }

      if (element.type === 'panel' || element.type === 'radio') {
        if (this.disabled) {
          // @ts-ignore
          element.value = element.values.find((val) => val.value === element.value)?.name
        }
      }

      if (element.type === 'datetime') {
        let splitDatetime: string[] = []

        if (typeof element.value === 'string') {
          splitDatetime = element?.value?.split(' ')

          const splitDate = splitDatetime[0]?.split('.').reverse().join('-')

          // @ts-ignore
          element.date = new Date(`${splitDate} ${splitDatetime[1]}`)
        } else {
          // @ts-ignore
          element.date = new Date()
        }
      }

      if (element.type === 'date') {
        // @ts-ignore
        element.value = new Date(element.value * 1000).toLocaleDateString('en-CA')
      }
    })

    const monthRefs: any = this.$refs.month || []
    if (monthRefs.length) {
      const minOffset = Math.min(...(monthRefs as any).map((el: any) => el.getBoundingClientRect().x))
      this.months = (monthRefs as any)
        .map((el: any) => ({
          el,
          offset: el.getBoundingClientRect().x - minOffset,
          width: el.getBoundingClientRect().width
        }))
        .sort((a: IFormDatetimesMonth, b: IFormDatetimesMonth) => (a.offset > b.offset) ? 1 : -1)
    }
    this.mountedForm = true
  }

  updated() {
    this.mountedForm = true
  }

  datetimeTranslate(x: number) {
    for (const idx in this.months) {
      this.months[idx].el.style.left = ''
    }

    const active: number = this.months.findIndex((month, idx) => {
      return month.offset + x < 0 && (!this.months[idx + 1] || this.months[idx + 1].offset + x > 0)
    }) || 0

    if (!this.months[active]) return

    let left = Math.abs(x) - this.months[active].offset
    const margin = 16

    if (this.months[active + 1]) {
      if (left + this.months[active].width + this.months[active].offset > this.months[active + 1].offset - margin) {
        left = Math.abs(this.months[active].width + this.months[active].offset - this.months[active + 1].offset) - margin
      }
    }

    this.months[active].el.style.transform = `translateX(${left}px)`
  }

  showCatalogItem({ elementIndex, index }: { elementIndex: number, index: string }) {
    this.catalogModal = {
      view: true,
      element: this.filteredForm[elementIndex],
      item: this.filteredForm[elementIndex].values[parseInt(index)],
      index
    }
  }
}
