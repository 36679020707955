
import { Component, Vue, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { ICounterForm, ICounterFormLayout } from '@/model/page/counter'
import { CounterModule } from '@/store/counter'
import { HandleApiRequest } from '@/utils/helper'
import { PageModule } from '@/store/page'
import dayjs from 'dayjs'

interface IInputForm {
  title: string
  prevValue: string
  value: string
}

@Component
export default class CounterForm extends Vue {
  isValidForm = false
  showInfoDialog = false
  dialogAlert = {
    viewedInputs: [] as number[],
    view: false,
    callbackNo: () => {}
  }

  @Prop({ required: true }) formSync!: ICounterFormLayout

  form = {} as ICounterFormLayout;

  moment = dayjs;

  get isDark() { return PageModule.isDark }

  get isReadonly() {
    return !this.form.isAllowed
  }

  get item() {
    return this.form.items ? this.form.items.slice(-1)[0] : []
  }

  get inputs() {
    const inputs: Array<IInputForm> = []

    if (!Object.keys(this.form).length) return inputs

    const item: any = this.item

    Array.from(new Array(3)).forEach((_, i) => {
      const input: IInputForm = {} as IInputForm

      if (!item[`previousValue${i + 1}`]) return

      input.title = item[`valueTitle${i + 1}`]
      input.prevValue = item[`previousValue${i + 1}`]
      input.value = item[`value${i + 1}`]

      inputs.push(input)
    })

    return inputs
  }

  pushService(item: Record<string, string | number>): void {
    if (item.correctionServiceId) { this.$router.push(`/services/${item.correctionServiceId}`) }
  }

  consumption(item: any, index: number) {
    return (parseFloat(item[`value${index}`]) - parseFloat(item[`previousValue${index}`] || 0))
  }

  setEditName(item: ICounterForm) {
    this.$set(item, 'editName', true)
    this.$nextTick(() => {
      (this.$refs.inputName as any)[0].focus()
    })
  }

  handleInputValue(item: any, index: number, event: InputEvent, needRerender: boolean = false) {
    const isDot = event.data === '.'
    const isDeleteKey = event.data === null
    const input = event?.target as HTMLInputElement
    const hasInputDot = (input?.value).includes('.')
    const average = parseFloat(item[`average${index}`])
    const value = input?.value && !event.data && !hasInputDot && needRerender
      ? (parseInt(input.value) || '')
      : parseFloat(item[`value${index}`] + parseInt(needRerender ? event.data || '' : '') || '')
    const prevValue = parseFloat(item[`previousValue${index}`] || 0)
    const isShowedDialogOnThisInput = this.dialogAlert.viewedInputs.includes(item.id)

    this.$nextTick(() => {
      if (((value as number) - prevValue) >= average && !isShowedDialogOnThisInput) {
        this.dialogAlert = {
          view: true,
          viewedInputs: [...this.dialogAlert.viewedInputs, item.id],
          callbackNo: () => {
            this.$set(item, `value${index}`, '')
            this.dialogAlert.view = false
            this.dialogAlert.viewedInputs = this.dialogAlert.viewedInputs.slice(0, -1)
          }
        }
      }
    })

    if (!needRerender) return

    if (hasInputDot) {
      this.$set(item, `value${index}`, input.value.slice(0, input.value.indexOf('.')))
      return
    }

    if (isDot) {
      // Необходимо для удаления точки из текстового поля
      this.$set(item, `value${index}`, (item[`value${index}`] + '0'))
      this.$nextTick(() => {
        this.$set(item, `value${index}`, (item[`value${index}`]).slice(0, -1))
      })
    } else if (event.data) {
      this.$set(item, `value${index}`, (item[`value${index}`] || '') + event.data)
    } else if (isDeleteKey) {
      this.$set(item, `value${index}`, (item[`value${index}`] || '').slice(0, -1))
    } else if (input && input.value && !event.data && !hasInputDot && needRerender) {
      this.$set(item, `value${index}`, input.value)
    }
  }

  @HandleApiRequest()
  async updateMetricName(item: ICounterForm) {
    await CounterModule.updateMetricName({ label: item.updatedName || '', id: item.meteringDeviceId })
    item.editName = false
    item.meteringDeviceLabel = item.updatedName || ''
    item.updatedName = ''
  }

  inputsIndexes(meteringTypeId: number): Array<number> {
    switch (meteringTypeId) {
      case 5: return [1, 2, 3]
      case 4: return [1, 2]
      default: return [1]
    }
  }

  required(value: string) {
    return !!value || 'Обязательное поле'
  }

  shouldMore(more: string) {
    return (value: string) => {
      return parseFloat(value) >= parseFloat(more || '0') || `Значение должно быть больше ${more}`
    }
  }

  mounted():void {
    this.form = JSON.parse(JSON.stringify(this.formSync))
    this.form.items.forEach((item) => {
      item.updatedName = item.meteringDeviceLabel
    })
  }
}
