
import { Component, Ref, Vue } from 'vue-property-decorator'
import { HandleApiRequest } from '@/utils/helper'

import { PageModule } from '@/store/page'
import { ServiceModule } from '@/store/service'
import { PassModule } from '@/store/pass'

import FormParser from '@/components/general/FormParser.vue'

// eslint-disable-next-line no-unused-vars
import { IServiceFull, ComputedServiceForm, PostServiceForm, IFormElement } from '@/model/page/service'
import { $get, $post } from '@/plugins/axios'
import router from '@/router'
import dayjs from 'dayjs'

@Component({
  components: {
    FormParser
  }
})
export default class ServicePageId extends Vue {
  @Ref('formParser') formParser!: FormParser;
  @Ref('formParser2') formParser2!: FormParser;

  service = {} as IServiceFull
  isLoading = true
  height = '500px'
  acceptRules = false
  modalShow = false
  tab = ''
  incidentModalShown = false
  incidentInfoModalShown = false
  successModalShown = false
  incidentData = {}
  incidentInfoData = {}
  successMessage = {}
  moment = dayjs
  loadings = { subscribe: false, incident: false }
  submitFormLoading = false

  get isEmptyForm() {
    return !this.service?.orderForm?.length
  }

  get showDescription() {
    return (this.service.fullDescription && !this.isEmptyForm) || this.service?.files?.length
  }

  get placeId() {
    return PageModule.placeId
  }

  get buildingId() {
    return PageModule.buildingId
  }

  get agreement() {
    return ServiceModule.agreement
  }

  async handleForm(formData: ComputedServiceForm) {
    const force = this.incidentModalShown ? 1 : 0
    try {
      this.submitFormLoading = true
      const { id } = this.$route.params

      if (['pass', 'permanent'].includes(id)) {
        const isPermanent = id === 'permanent'

        await PassModule.submitCreatePass({ form: formData, isPermanent })

        this.$router.push(`/places/passes?type=${isPermanent ? 'permanent' : 'pass'}`)
      } else {
        if (force === 1) this.loadings.incident = true

        const response = await ServiceModule.submitService({ id, formData, force })

        if (this.service.isPayableAtCreating && response.paymentLink) {
          const link = document.createElement('a')
          link.href = response.paymentContext ? `/pay/init?c=${response.paymentContext}` : response.paymentLink
          link.setAttribute('target', '_blank')
          document.body.appendChild(link)
          link.click()
          link.remove()
        }
        this.$router.push(`/places/orders/${response.orderId}?scopeTypeId=${response.scopeTypeId}`)
      }
    } catch (error) {
      const res = error.response
      const isIncident = res.status === 409 && res.data?.data?.showIncidentsWarning
      if (!isIncident) this.$handleApiError(error, this)

      if (error.response) {
        if (isIncident) {
          this.incidentData = res.data.data
          this.incidentModalShown = true
        }
      }
    } finally {
      if (force === 1) this.loadings.incident = true
      this.submitFormLoading = false
    }
  }

  async createPreOrder(formData: ComputedServiceForm): Promise<void> {
    formData = formData.filter((elementData) => elementData?.value)

    try {
      const { orderId }: PostServiceForm = (await $post(`/services/${this.service.id}/pre-order`, {
        formData
      }))?.data

      await router.push(`/services/orders/${orderId}/chat?scopeTypeId=2`)
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }

  async onAskQuestionClick(formParser: number): Promise<void> {
    const formParserComponent = formParser === 1 ? this.formParser : this.formParser2

    // Если форма пустая
    if (!formParserComponent) await this.createPreOrder([])

    await formParserComponent.handleForm(this.createPreOrder, false)
  }

  async getIncidentInfo(id: number): Promise<void> {
    try {
      const { data } = await $get(`/incidents/${id}`)
      this.incidentInfoData = data
      this.incidentInfoModalShown = true
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }

  async subscribeToIncident(): Promise<void> {
    this.loadings.subscribe = true
    try {
      const { data } = await $post(`/incidents/subscribe/${this.service.id}`)
      this.successMessage = data
      this.incidentModalShown = false
      this.successModalShown = true
    } catch (error) {
      this.$handleApiError(error, this)
    } finally {
      this.loadings.subscribe = false
    }
  }

  @HandleApiRequest()
  async mounted() {
    const id = this.$route.params.id

    if (id === 'pass') {
      this.service = await PassModule.getPassesForm()
    } else if (id === 'permanent') {
      this.service = await PassModule.getPassesPermanentForm()
    } else {
      this.service = await ServiceModule.getService(id)

      if (this.service.isAgreementRequired) {
        await ServiceModule.getServiceAgreement(this.service.id)
      }

      if (this.$route.query.orderId) {
        const { orderForm }: IServiceFull = (await $get(`/orders/${this.$route.query.orderId}/form?scopeTypeId=2`))?.data

        this.service.orderForm = orderForm
      }
    }
  }
}
