
import { Component, Vue, Prop } from 'vue-property-decorator'
// components
import AppPhoneField from '@/components/general/AppPhoneField.vue'
import { VueRecaptcha } from 'vue-recaptcha'
import { AuthModule } from '@/store/auth'
import AppPasswordInput from '@/components/app/AppPasswordInput.vue'
@Component({
  components: {
    AppPhoneField,
    VueRecaptcha,
    AppPasswordInput
  }
})
export default class LoginCard extends Vue {
  @Prop({ type: String, default: 'primary' }) color!: string;

  // data
  title = 'Войти'
  buttonTitle = 'Войти'

  phoneNumber = '';
  agreement = false;
  code = ''
  password = '';
  captcha = '';
  forgotPassword = false;

  showAgreement = true;
  showCodeField = false;
  showPasswordField = false;
  showCaptcha = false;
  showLoginField = true;

  isAgreementModalShow = false;
  isAgreementLoading = false;

  showConfirm = false;

  codeTimeCount = 60;
  // getters
  get isValid(): boolean {
    if (!this.phoneNumber || !this.agreement) return false
    if (this.showCaptcha && !this.captcha) return false
    return true
  }

  get agreementText() {
    return AuthModule.agreement
  }

  // actions
  handleLoginClick(): void {
    if (this.showCodeField) {
      this.$emit('code-auth', { code: this.code, phoneNumber: this.phoneNumber, captcha: this.captcha })
      return
    }
    if (this.showPasswordField) {
      this.$emit('password-auth', { phoneNumber: this.phoneNumber, password: this.password, captcha: this.captcha })
      return
    }
    if (!this.showCodeField && !this.showCodeField) {
      if (!this.showCaptcha) {
        this.sendCode()
      } else {
        this.$emit('captcha-validate', { phoneNumber: this.phoneNumber, captcha: this.captcha, forgot: this.forgotPassword })
      }
    }
  }

  sendCode(): void {
    this.$emit('ask-for-auth', { phoneNumber: this.phoneNumber, captcha: this.captcha, forgot: this.forgotPassword })
    this.codeTimeCount = 60
    this.codeTimeout()
  }

  handleForgotPassword(): void {
    this.forgotPassword = true
    this.showPasswordField = false
    this.showCaptcha = false
    this.captcha = ''
    this.showConfirm = false
    this.$emit('forgot-password', { phoneNumber: this.phoneNumber, forgotPassword: this.forgotPassword })
    this.codeTimeout()
  }

  recaptcha(token: string):void {
    this.captcha = token
  }

  clearCaptcha():void {
    this.captcha = ''
  }

  codeTimeout():void {
    const interval = setInterval(() => {
      this.codeTimeCount -= 1
      if (this.codeTimeCount === 0) clearInterval(interval)
    }, 1000)
  }

  async mounted(): Promise<void> {
    this.isAgreementLoading = true
    await AuthModule.getAgreement()
    this.isAgreementLoading = false
  }
}
